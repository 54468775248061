import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import SplitterLayout from "react-splitter-layout";

import Box from "@mui/material/Box";
import { Button, Checkbox } from "@mui/material";

import { AppDispatch, RootState } from "../../../redux/store";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import {
  GetAllProviders,
  GetProviderTypes,
} from "../../../redux/features/providers-main/provider-list-slice";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import { GetScheduleList } from "../../../redux/features/schedules-main/schedule-list-slice";
import { getPlanById } from "../../../redux/features/plannings/planning-slice";

import PlannerClientList from "../planning-Lists/PlannerClientList";
import PlannerProviderList from "../planning-Lists/PlannerProviderList";
import DataTable from "../data-View/DataTable";
import PlanningCalenderNew from "../manualBooking-Calender/PlanningCalenderNew";
import PlanningMap from "../planningMap/PlanningMap";

import { ClientForms, utilizationdata } from "../../../types/client";
import { ProviderForms } from "../../../types/provider";
import { IPlanDetail, PlannerFilter } from "../../../types/planning";
import { filterPlanners } from "../../../helpers/planner-filter-helper/planners-filter-helper";
import { defaultPlanningFilter } from "../../../constants/planning";

import "react-splitter-layout/lib/index.css";
import "../../maps/Map-Details/mapDetails.scss";
import { setPlanningFilterValues } from "../../../redux/features/plannings/planning-filter-slice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export interface IPlanninglistSelect {
  id: string;
  group: boolean;
  single: boolean;
  clientGroup?: boolean;
}

interface IPlanDetails {
  setchangedList: React.Dispatch<React.SetStateAction<boolean>>;
}
const PlanningsDetails: React.FC<IPlanDetails> = ({ setchangedList }) => {
  // #region variable region
  //dispatch defined
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  //binding action creators
  const dispatchAction = bindActionCreators(
    {
      GetScheduleList,
      GetAllClientData,
      GetAllProviders,
      setResponseValue,
      getPlanById,
    },
    dispatch
  );
  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  const currentPlan = useSelector<RootState, IPlanDetail>(
    (state) => state.planSlice.currentPlan
  );

  const clientData = useSelector<RootState, ClientForms[]>(
    (state) => state.clientListSlice.listData
  );

  //stores client data according utlization date range
  const UtilizationData = useSelector<RootState, utilizationdata>(
    (state) => state.clientListSlice.utilizationListData
  );
  // handle change in client data
  const isDataUpdate = useSelector<RootState, boolean>(
    (state) => state.clientListSlice.dataUpdate
  );
  //Variable stores filtervalues
  const filterValues = useSelector<RootState, PlannerFilter>(
    (state) => state.planningFilterSlice.PlanningFilter
  );

  //Variable stores searchValues
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  //used to store path location
  const { pathname } = useLocation();

  //Provider Data fetching
  const providerData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  //selected tab to display proper pages
  const [selectedTab, setSelectedTab] = useState<string>("Clients");

  //selected tab to display calendera and map
  const [selectedApplicationTab, setSelectedApplicationTabs] =
    useState<string>("Calender");

  //change to handle booking delete and edit
  const [bookingChange, setBookingChange] = useState<boolean>(false);

  /*
    manage different types of lists
    - selectedClients & selectedProviderLists = Filtered + Checked
    - filteredClientList & filteredProviderList = Filtered
    - selectedClientIds & selectedProviderIds = for DataTable & track single id list
    - filteredIds = Filtered ids for DataTable
    // - calendarClientList & calendarProviderList = for Calender (deprecate this!)
  */
  const [filteredIds, setFilteredIds] = useState<string[]>([]);

  const [selectedClients, setSelectedClients] = useState<ClientForms[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<ProviderForms[]>(
    []
  );

  const [selectedClientIds, setSelectedClientIds] = useState<
    IPlanninglistSelect[]
  >([]);
  const [selectedProviderIds, setSelectedProviderIds] = useState<
    IPlanninglistSelect[]
  >([]);

  const [filteredClientList, setFilteredClientList] = useState<ClientForms[]>(
    []
  );
  const [filteredProviderList, setFilteredProviderList] = useState<
    ProviderForms[]
  >([]);

  // const [calendarClientList, setCalendarClientList] = useState<ClientForms[]>([]);
  // const [calendarProviderList, setCalendarProviderList] = useState<ProviderForms[]>([]);

  //track filter applied status
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  //track check status
  const [isAllSelected, setIsAllSelected] = useState<{
    client: boolean;
    provider: boolean;
  }>({
    client: false,
    provider: false,
  });
  //handle drawer
  const [drawerOpen, setDrawerOpen] = useState(true);
  // #endregion

  // #region methods region

  //When user clicks "Add to Calendar" button in DataTable, this method is called
  const addIntoCalendar = (cmd: any) => {
    let filterProvider: any[] = [];
    let filterClient: any[] = [];
    cmd.forEach((cmd: any) => {
      if (cmd.type === "Employee") {
        // SetProvider
        if (isDataUpdate) {
          filterProvider = [
            ...filterProvider,
            ...UtilizationData.Provider.filter((provider) => {
              return provider.employmentDetails.id === cmd.id;
            }),
          ];
        } else {
          filterProvider = [
            ...filterProvider,
            ...providerData.filter((provider) => {
              return provider.employmentDetails.id === cmd.id;
            }),
          ];
        }
      } else if (cmd.type === "Client") {
        filterClient = [
          ...filterClient,
          ...clientData.filter((client) => {
            return client.clientBasicDetails.id === cmd.id;
          }),
        ];
      }
    });

    //updated calendar list
    setSelectedProviders(filterProvider);
    setSelectedClients(filterClient);
    setSelectedApplicationTabs("Calender");
  };

  const handleSelectAll = () => {
    //mark plan changed
    setchangedList(true);

    //1. for Clients Tab
    if (selectedTab === "Clients" && filteredClientList.length > 0) {
      //1a. if all clients are already selected
      if (isAllSelected.client === true) {
        //cleanup client selection
        setSelectedClientIds([]);
        setSelectedClients([]);
        setIsAllSelected({ ...isAllSelected, client: false });

        //for client's supervior + provider teams, also cleanup
        if (selectedProviders.length > 0) {
          let data = selectedProviders.filter((item) =>
            selectedProviderIds.some(
              (ele) =>
                ele.id === item.employmentDetails.id &&
                (ele.group || ele.single)
            )
          );
          setSelectedProviderIds(
            selectedProviderIds.map((item) => {
              return {
                ...item,
                clientGroup: false,
              };
            })
          );
          setSelectedProviders(data);
        }
        return;
      }

      //1b. if all clients are not selected, now select them
      setIsAllSelected({ ...isAllSelected, client: true });

      //get all selected clients
      let newClientSelection: any[] = [];
      newClientSelection = selectedClientIds.map((client) => {
        const matchedItem = filteredClientList.find(
          (item) => item.clientBasicDetails.id === client.id
        );
        if (matchedItem) {
          return {
            ...client,
            single: true,
            group: matchedItem.clientBasicDetails.supervisorId
              ? true
              : client.group,
          };
        }
        return client;
      });
      // console.log("newClientSelection, selectedClientIds:", newClientSelection, selectedClientIds);

      filteredClientList.forEach((item) => {
        if (
          !selectedClientIds.some(
            (client) => client.id === item.clientBasicDetails.id
          )
        ) {
          newClientSelection.push({
            id: item.clientBasicDetails.id,
            single: true,
            group: !!item.clientBasicDetails.supervisorId,
          });
        }
      });
      setSelectedClients(filteredClientList);
      setSelectedClientIds(newClientSelection);

      const clientSupervisor = filteredClientList
        .filter((item) => item.clientBasicDetails?.supervisorId)
        .map((ele) => ele.clientBasicDetails.supervisorId);

      let providersId: string[] = [];
      const [data] = filteredProviderList
        .filter((item) => clientSupervisor.includes(item.employmentDetails.id))
        .map((item) => item.employmentDetails.providerList);

      if (data?.length > 0 && clientSupervisor?.length > 0) {
        providersId = Array.from(new Set([...data, ...clientSupervisor]));
      }
      let providers: ProviderForms[] = [];
      filteredProviderList.forEach((item) => {
        if (
          providersId.includes(item.employmentDetails.id) &&
          item.employmentDetails.isSupervisor
        ) {
          providers.push(item);
        }
      });
      setSelectedProviders(providers);
      return;
    }

    //2. for Employees Tab
    if (selectedTab === "Employees" && filteredProviderList.length > 0) {
      //2a. if all providers are already selected
      if (isAllSelected.provider === true) {
        setIsAllSelected({ ...isAllSelected, provider: false });
        setSelectedProviders([]);
        setSelectedProviderIds([]);
        return;
      }
      //2b. if all providers are not selected, now select them
      setIsAllSelected({ ...isAllSelected, provider: true });
      const newProviderSelection = selectedProviderIds.map((client) => {
        const matchedItem = filteredProviderList.find(
          (item) => item.employmentDetails.id === client.id
        );
        if (matchedItem) {
          return {
            ...client,
            single: true,
            group: matchedItem ? true : client.group,
          };
        }
        return client;
      });
      filteredProviderList.forEach((item) => {
        if (
          !selectedProviderIds.some(
            (client) => client.id === item.employmentDetails.id
          )
        ) {
          newProviderSelection.push({
            id: item.employmentDetails.id,
            single: true,
            group: item.employmentDetails.isSupervisor,
          });
        }
      });
      setSelectedProviders(filteredProviderList);
      setSelectedProviderIds(newProviderSelection);
    }
  };

  const excludeClientWithNoAvailabilityData = () => {
    let data = isDataUpdate
      ? UtilizationData.Clients
      : clientData?.filter((item) => item.availabilityDetails !== undefined);

    return data;
  };

  const excludeProviderWithNoAvailabilityData = () => {
    let arr: ProviderForms[] = [];
    providerData?.forEach((item) => {
      if (item?.availabilityDetails?.length > 0) {
        arr.push(item);
      }
    });

    return arr;
  };
  // #endregion

  // #region useEffect region

  //fetch all provider and all client api call
  useEffect(() => {
    // console.log("planningDetails - fetch all client and provider data");
    dispatchActionRef.current.GetAllClientData();
    dispatchActionRef.current.GetAllProviders();
    dispatchActionRef.current.GetScheduleList();
  }, []);

  /*useEffect(() => {
    console.log("useEffect - clientData change, update selectedClients");
    let newSelectedClients: any[] = selectedClients.map(
      (client) =>
        client &&
        clientData.find(
          (newClient) =>
            newClient.clientBasicDetails.id === client.clientBasicDetails.id
        )
    );
    setSelectedClients(newSelectedClients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData?.length]);
  */

  function initalCall() {
    let clientarr: ClientForms[] = [];
    let providerarr: ProviderForms[] = [];
    // Define the function to pre-fill the planner with client and provider data.
    const preFilledPlanner = () => {
      const { plan } = currentPlan;

      // Function to find and add client/provider to their respective arrays
      const findAndAdd = (
        ids: string[],
        dataSource: any[],
        resultArray: any[],
        idKey: string
      ) => {
        ids.forEach((id) => {
          const result = dataSource.find((item) => item[idKey].id === id);
          if (result) resultArray.push(result);
        });
      };

      if (plan) {
        if (
          (plan.clients?.length > 0 && clientData.length > 0) ||
          (selectedClients.length > 0 && clientData.length > 0)
        ) {
          let clientIds: string[] = [];
          //if there is temporary selected Client and client data gets updated
          if (selectedClients.length > 0) {
            clientIds = clientData
              .filter((item) =>
                selectedClients.some(
                  (client) =>
                    client.clientBasicDetails.id === item.clientBasicDetails.id
                )
              )
              .map((ele) => ele.clientBasicDetails.id);
          }
          findAndAdd(
            selectedClients.length > 0 ? clientIds : plan.clients,
            clientData,
            clientarr,
            "clientBasicDetails"
          );
        }
        if (
          (plan.providers?.length > 0 && providerData.length > 0) ||
          selectedProviders.length > 0 ||
          providerData.length > 0
        ) {
          let providerIds: string[] = [];
          //if temporary selected provider and provider data gets updated
          if (selectedProviders.length > 0) {
            providerIds = providerData
              .filter((item) =>
                selectedProviders.some(
                  (pro) =>
                    pro.employmentDetails.id === item.employmentDetails.id
                )
              )
              .map((ele) => ele.employmentDetails.id);
          }

          findAndAdd(
            selectedProviders.length > 0 ? providerIds : plan.providers,
            providerData,
            providerarr,
            "employmentDetails"
          );
        }
      }
    };

    // Pre-fill the planner
    if (pathname === "/planning-detail/" + currentPlan?.plan?.id) {
      // console.log("populate plan list", clientData.length, providerData.length);
      preFilledPlanner();
      setSelectedClients(clientarr);
      setSelectedProviders(providerarr);

      const clientID = Array.from(new Set(clientarr)).map(
        (item) => item.clientBasicDetails.id
      );
      const providerID = Array.from(new Set(providerarr)).map(
        (item) => item.employmentDetails.id
      );
      const clients = clientID.map((item) => {
        return {
          id: item,
          group: false,
          single: true,
        };
      });
      const providers = providerID.map((item) => {
        return {
          id: item,
          group: false,
          single: true,
          clientGroup: false,
        };
      });
      setSelectedClientIds(clients);
      setSelectedProviderIds(providers);
    }
  }

  useEffect(() => {
    // console.log("useEffect - pre-load plan detail");
    initalCall();
    return () => {
      // console.log("cleanup");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname, //when location has pathname
    clientData,
    providerData,
    currentPlan?.plan?.id, //when currentPlan changes
  ]);

  useEffect(() => {
    if (currentPlan?.plan?.id) {
      // console.log("useEffect - when bookingChange -> reload plan", currentPlan?.plan?.id, bookingChange);
      dispatchActionRef.current.getPlanById({ Id: currentPlan?.plan?.id });
    }
  }, [currentPlan?.plan?.id]);

  useEffect(() => {
    // console.log("useEffect - apply filter", filterValues === defaultPlanningFilter);

    if (clientData?.length > 0) {
      const clients = filterPlanners<ClientForms>(
        filterValues,
        searchValue,
        excludeClientWithNoAvailabilityData()
      );
      setFilteredClientList(clients);
    }

    if (providerData?.length > 0) {
      const providers = filterPlanners<ProviderForms>(
        filterValues,
        searchValue,
        excludeProviderWithNoAvailabilityData()
      );
      setFilteredProviderList(providers);
    }

    if (filterValues !== defaultPlanningFilter && searchValue === "") {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    clientData,
    providerData,
    currentPlan?.plan?.id, //currentPlan change

    isDataUpdate, //clientData change
    filterValues, //filterValues change
    searchValue, //searchValue change
  ]);

  useEffect(() => {
    dispatch(GetProviderTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("useEffect - filteredClientList/filteredProviderList changed --> update filteredIds");
    let Ids: string[] = [];
    filteredClientList?.forEach((item) => Ids.push(item.clientBasicDetails.id));
    filteredProviderList?.forEach((item) =>
      Ids.push(item.employmentDetails.id)
    );
    setFilteredIds(Ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClientList?.length, filteredProviderList?.length]);

  useEffect(() => {
    //prefill only once
    if (currentPlan.plan.appliedFilters !== null) {
      dispatch(setPlanningFilterValues(currentPlan.plan.appliedFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan.plan?.appliedFilters]);

  // useEffect(() => {
  //   console.log("when filteredCliens/filteredProviderList changes --> update selectedClients/selectedProviders");
  //   const filteredClients = selectedClients.filter((item: any) =>
  //     filteredClientList.some(
  //       (ele) => ele.clientBasicDetails.id === item.clientBasicDetails.id
  //     )
  //   );
  //   setSelectedClients(filteredClients);

  //   const filteredProviders = selectedProviders.filter((item: any) =>
  //       filteredProviderList.some(
  //         (ele) => ele.employmentDetails.id === item.employmentDetails.id
  //       )
  //     );
  //   setSelectedProviders(filteredProviders);
  // }, [filteredClientList, filteredProviderList]);
  // #endregion
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", width: "100%" }} className="planning-details">
        <Box
          className="planningList"
          sx={{
            width: drawerOpen ? "10%" : "5px",
            minWidth: drawerOpen ? "390px" : "5px",
          }}
        >
          {drawerOpen && (
            <Box px={"0px"}>
              <Box className="listHeader">
                <ul className="innerTabs">
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("Clients")}
                      className={selectedTab === "Clients" ? "active" : ""}
                    >
                      Clients&nbsp;
                      <span style={{ fontSize: 14 }}>
                        ({filteredClientList.length})
                      </span>
                    </button>
                  </li>
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("Employees")}
                      className={selectedTab === "Employees" ? "active" : ""}
                    >
                      Employees&nbsp;
                      <span style={{ fontSize: 14 }}>
                        ({filteredProviderList.length})
                      </span>
                    </button>
                  </li>
                </ul>
                <div>
                  <Checkbox
                    onClick={() => handleSelectAll()}
                    checked={
                      selectedTab === "Clients"
                        ? isAllSelected.client
                        : isAllSelected.provider
                    }
                  />
                  All
                </div>
              </Box>

              <div>
                {selectedTab === "Clients" && (
                  <PlannerClientList
                    clientsListData={filteredClientList}
                    providerListData={providerData}
                    activeTab={false}
                    setSelectedClients={setSelectedClients}
                    allSelectedClients={selectedClients}
                    selectedApplicationTab={selectedApplicationTab}
                    setSelectedProvider={setSelectedProviders}
                    selectedProvider={selectedProviders}
                    bookingData={currentPlan.bookingData}
                    setchangedList={setchangedList}
                    selectedClientIds={selectedClientIds}
                    setSelectedClientIds={setSelectedClientIds}
                    selectedProviderIds={selectedProviderIds}
                    setSelectedProviderIds={setSelectedProviderIds}
                    isAllSelected={isAllSelected}
                    setIsAllSelected={setIsAllSelected}
                    isFilterApplied={isFilterApplied}
                    filteredProviderList={filteredProviderList}
                  />
                )}
                {selectedTab === "Employees" && (
                  <PlannerProviderList
                    providerListData={providerData}
                    filteredProviderData={filteredProviderList}
                    selectedProvider={selectedProviders}
                    setSelectedProviders={setSelectedProviders}
                    setchangedList={setchangedList}
                    isFilterApplied={isFilterApplied}
                    activeTab={false}
                    selectedProviderIds={selectedProviderIds}
                    setSelectedProviderIds={setSelectedProviderIds}
                    setIsAllSelected={setIsAllSelected}
                    isAllSelected={isAllSelected}
                  />
                )}
              </div>
            </Box>
          )}
        </Box>

        <Box
          px={"25px"}
          sx={{ width: drawerOpen ? "80%" : "100%", transition: "width 0.3s" }}
        >
          <div>
            <Box display={"flex"}>
              <ul className="innerTabs">
                <li className="innerTabs__item">
                  <Button
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    sx={{ minWidth: "25px" }}
                  >
                    {drawerOpen ? (
                      <ArrowBackIosIcon color="primary" />
                    ) : (
                      <ArrowForwardIosIcon color="primary" />
                    )}
                  </Button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedApplicationTabs("Calender")}
                    className={
                      selectedApplicationTab === "Calender" ? "active" : ""
                    }
                  >
                    Calender
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedApplicationTabs("Map")}
                    className={selectedApplicationTab === "Map" ? "active" : ""}
                  >
                    Map
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedApplicationTabs("Data")}
                    className={
                      selectedApplicationTab === "Data" ? "active" : ""
                    }
                  >
                    Data
                  </button>
                </li>
              </ul>
            </Box>
          </div>
          {selectedApplicationTab === "Calender" && (
            <PlanningCalenderNew
              selectedClients={selectedClients}
              selectedProviders={selectedProviders}
              isDataEdited={bookingChange}
              setIsDataEdited={setBookingChange}
              drawerOpen={drawerOpen}
            />
          )}
          {selectedApplicationTab === "Data" && (
            <div className="dataTable-Container">
              <DataTable
                addIntoCalendar={addIntoCalendar}
                type="Calendar"
                filteredIds={filteredIds}
              />
            </div>
          )}
          {selectedApplicationTab === "Map" && (
            <PlanningMap
              providerListData={filteredProviderList}
              clientsListData={filteredClientList}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PlanningsDetails;
