import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  FormHelperText,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
} from "@mui/material";
import { Logout, Upload } from "@mui/icons-material";
import { RootState } from "../../redux/store";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useUser, useDescope } from "@descope/react-sdk";
import { isValidFileUploaded } from "../../utils/Image-validation";
import {
  getOrgInfo
} from "../../redux/features/organization/organization-slice";
import ABAEngineLogo from "../../assets/images/logos/abaengine.png";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import CompanyUserService from "../../service/org.service";
import Loader from "../../layouts/loader/Loader";
import Stripe from "stripe";
import SubscriptionCard from "../../components/payment/SubscriptionCard";
import { getProducts } from "../../redux/features/plan-billing/plan-billing-slice";
import { IStripeProduct, ITenantUsage } from "../../types/product";
import { OrgData } from "../../types/company";

const stripe = new Stripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`, {
  apiVersion: "2022-11-15",
});

const OrganizationOnboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //get session token
  const { user } = useUser();
  const sdk = useDescope();

  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //org name
  const [orgName, setOrgName] = useState<string>(org?.orgName || "");

  //org url
  const [orgUrl, setOrgUrl] = useState<string>(org?.orgUrl || "");

  //org logo file
  const [orgLogoFile, setOrgLogoFile] = useState<any>(null);

  //preview image
  const [previewImage, setPreviewImage] = useState<string>("");

  //handle image error
  const [imageError, setImageError] = useState<string>("");

  const [orgService, setOrgService] = useState<string[]>(org?.orgService || []);

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentPage, setPaymentPage] = useState(false);
  const [orgExists, setOrgExists] = useState(org?.id !== "" ? true : false);

  const products = useSelector<RootState, IStripeProduct[]>(
    (state) => state.planBillingSlice.products
  );
  const tenantUsage = useSelector<RootState, ITenantUsage>(
    (state) => state.planBillingSlice.tenantUsage
  );
  const [adminSeats, setAdminSeats] = useState(0);

  //removes focus when enter is pressed
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      // dispatch(setProfilePicture(null));
    } else if (isValidFileUploaded(e.target.files[0])) {
      setImageError("");
      const src = URL.createObjectURL(e.target.files[0]);
      setPreviewImage(src);
      setOrgLogoFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let logoFile = orgLogoFile,
      name = orgName;
   
    try {
      const createOrg = await CompanyUserService.postOrganization(
        name,
        orgUrl,
        logoFile,
        orgService
      );
      

      if (createOrg) {
        // console.log("createOrg", createOrg);
        
        //a. make sure customAttribute is re-fetch quickly
        await sdk.selectTenant(createOrg.data.descope_tenantId);
        await sdk.refresh(sdk.getRefreshToken());
        await sdk.me();

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: createOrg.message })
        );
        dispatch(getOrgInfo({ useLoader: true }));

        if (user?.email !== undefined && user?.customAttributes?.currentTenantId) {
          setPaymentPage(true);
        }
      }
    } catch (e: any) {
      console.log("error", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      setIsLoading(false);
    }
  };

  const steps = [
    "Register User",
    "Setup Organization",
    //"Invite Team Members",
    "Choose Plan",
    "Onboarding Complete",
  ];

  const handleUserLogout = async() => {
    localStorage.removeItem("selectedTab");
    localStorage.removeItem("setCounter");
    await sdk.logout();
    navigate("/");
  };

  useEffect(() => {
    setOrgExists(org?.id !== "" ? true : false);

    //check if user has a tenantId --> if yes, setup org complete. 
    //then show payment page
    if (user?.customAttributes?.currentTenantId) {
      setPaymentPage(true);
    }
  }, [user, org]);

  useEffect(() => {
    if (!products || products.length === 0) {
      if (paymentPage) {
        dispatch(getProducts());
      }
    }
  }, [paymentPage]);

  // console.log("user", user, org, orgExists);

  return (
    <Stack direction={"row"} sx={{ height: "100vh" }}>
      <Box
        sx={{
          backgroundColor: "#F6FBFF",
          width: "20%",
          paddingX: "32px",
          paddingTop: "62px",
        }}
      >
        <Box
          component={"img"}
          src={ABAEngineLogo}
          sx={{ width: "211px", marginBottom: "34px" }}
        ></Box>
        <Box>
          <Stepper orientation="vertical" activeStep={paymentPage ? 2 : 1}>
            {steps.map((step) => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: "116px",
          paddingTop: "50px",
          width: "100%",
        }}
      >
        {isLoading && <Loader isShow={isLoading} />}

        {/* Setup Organization */}
        {!orgExists && user && (
          <ValidatorForm onSubmit={handleSubmit}>
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: "700",
                marginBottom: "70px",
              }}
            >
              Setup Organization
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
              <InputLabel
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                Company Name *
              </InputLabel>
              <TextValidator
                id="orgName"
                type="text"
                name="orgName"
                value={orgName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    removeFocus("orgName");
                  }
                }}
                style={{ width: "100%" }}
                onChange={(e: any) => setOrgName(e.target.value)}
                validators={["required"]}
                errorMessages={["Organization name is required"]}
              />
            </Box>
            <Box className="fromGroup">
              <InputLabel
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
                // className="fromLabel"
              >
                Company Service *
              </InputLabel>
              <div className="fromGroup-chips">
                {["Autism Practice", "Mental Health"].map((item) => (
                  <Card
                    className={
                      orgService.includes(item) ? "fromGroup-chip-active" : ""
                    }
                    onClick={() => {
                      if (orgService.includes(item)) {
                        return setOrgService((pre) =>
                          pre.filter((i) => i !== item)
                        );
                      }
                      setOrgService((pre) => [item, ...pre]);
                    }}
                    key={item}
                  >
                    {item}
                  </Card>
                ))}
                <div style={{ width: "100%" }} />
                {false && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select at least 1 service
                  </FormHelperText>
                )}
              </div>
            </Box>
            <Box sx={{ marginBottom: "40px" }}>
              <InputLabel
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                Company Website *
              </InputLabel>
              <TextValidator
                id="orgUrl"
                type="text"
                name="orgUrl"
                value={orgUrl}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    removeFocus("orgUrl");
                  }
                }}
                style={{ width: "100%" }}
                onChange={(e: any) => setOrgUrl(e.target.value)}
                validators={["required"]}
                errorMessages={["Company website is required"]}
              />
            </Box>
            <Box sx={{ marginBottom: "40px" }}>
              {previewImage && (
                <Box
                  component={"img"}
                  src={previewImage}
                  alt="Company Logo"
                  sx={{
                    width: "100%",
                    height: "206px",
                    objectFit: "contain",
                  }}
                ></Box>
              )}
              <Button
                variant="contained"
                component="label"
                className="uploadCompanyProfile__btn"
                startIcon={<Upload />}
              >
                Upload Company Logo
                <input
                  accept="image/png, image/jpeg"
                  type="file"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFileChange(e);
                  }}
                />
              </Button>
              <FormHelperText
                style={{
                  color: "#d32f2f",
                  position: "absolute",

                  left: "60px",
                }}
              >
                {imageError}
              </FormHelperText>
            </Box>
            <Button
              type="submit"
              sx={{
                paddingX: "24px",
                paddingY: "8px",
                fontSize: "18px",
                fontWeight: "500",
                marginLeft: "auto",
                display: "flex",
              }}
            >
              Next
            </Button>
          </ValidatorForm>
        )}

        {/* Choose Plan */}
        {paymentPage && (
          <>
          <Grid container spacing={3}>
            {products.map((product: IStripeProduct, index: number) => (
              <SubscriptionCard
                key={index}
                product={product}
                // checkoutPayment={async () => {
                //   console.log("begin to redirect to checkout");
                //   await redirectToCheckout(product.productId, adminSeats);
                //   console.log("after checkout");
                //   dispatch(getOrgInfo({ useLoader: true }));
                // }}
                tenantUsage={tenantUsage}
                adminSeats={adminSeats}
                setAdminSeats={setAdminSeats}
              />
            ))}
          </Grid>

          {/* leave here for testing purpose */}
          <Grid container spacing={3}>
            <Logout onClick={handleUserLogout} className="logout-icon" />
          </Grid>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default OrganizationOnboard;
