import React, { useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useSession, useUser } from "@descope/react-sdk";
import { io } from "socket.io-client";
import Gleap from "gleap";

import { useAppDispatch, useAppSelector } from "./redux/store";
import { getInsuranceProviders } from "./redux/features/client-main/client-insurance-slice";
import { getAllClientIntakeStage } from "./redux/features/client-main/client-intake-stage";
import {
  getClientBoards,
  setClientTasks,
} from "./redux/features/client-main/client-board-slice";
import {
  getProviderBoards,
  setProviderTasks,
} from "./redux/features/providers-main/provider-board-slice";
import { getAllProviderOnBoardingStages } from "./redux/features/providers-main/provider-onboardingstage-slice";
import { getAllBusinessUnits } from "./redux/features/business-unit/business-unit";
import { getOrgInfo } from "./redux/features/organization/organization-slice";
import { getAllBookingsData } from "./redux/features/schedules-main/schedule-list-slice";
import { getDataCatalog } from "./redux/features/data-catalog/data-catalog-slice";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

import SignIn from "./pages/public-pages/sign-in/SignIn";
import SignUp from "./pages/public-pages/sign-up/SignUp";
import OrganizationOnboard from "./pages/organization-onboard/OrganizationOnboard";
import Clients from "./pages/clients/client/Clients";
import ClientDetails from "./pages/clients/client-details/ClientDetails";
import Schedules from "./pages/schedules-main/Schedules";
import ClientSchedules from "./pages/schedules-main/client-schedule/ClientSchedules";
import Providers from "./pages/providers/provider/Providers";
import ProvidersDetails from "./pages/providers/provider-details/ProvidersDetails";
import Assessments from "./pages/assessments/Assessments";
import CompanyProfile from "./pages/company-profile/CompanyProfile";
import UserManagement from "./pages/user-management/UserManagement";
import Maps from "./pages/maps/Maps";
import Tools from "./pages/tools/Tools";
import ImportDetails from "./pages/tools/import-tools/ImportDetails";
import ExportClientsProviderDetails from "./pages/tools/export-tools/ExportClientsProviderDetails";
import BusinessSettings from "./pages/settings/business-settings/BusinessSettings";
import Message from "./pages/messages/Message";
import ComposeMessage from "./pages/messages/message-form/Compose-message";
import MessageTemplateNew from "./pages/settings/business-settings/message-template/MessageTemplateNew";
import FormTracking from "./pages/forms/FormTracking";
import SurveyCreate from "./pages/settings/survey-creater/SurveyCreate";
import Embedform from "./pages/embed-survey/Embedform";
import Plannings from "./pages/Plannings/Plannings";
import Board from "./pages/Board/Board";
import PlanningTwo from "./pages/planningTwo/PlanningTwo";
import MyPlan from "./pages/plan-billing/my-plan/MyPlan";
import ViewPlan from "./pages/plan-billing/view-plan/ViewPlan";
import AppIntegrations from "./pages/app-integrations/AppIntegrations";

import { AppUrls } from "./AppUrls";

import { BoardStage, CardData } from "./types/board";

interface CardsInitialState {
  [key: string]: CardData[];
}

const AppRoutes: React.FC = () => {
  const socket = io(`${process.env.REACT_APP_SOCKET_IO}`, {
    autoConnect: false,
  });
  // #region variables region

  // Descope Integration
  const { isAuthenticated } = useSession();
  const { user } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;

  //checks for business unit data change
  const businessUnitDataChange = useAppSelector(
    (state) => state.businessUnitsSlice.dataChange
  );

  // #endregion

  const dispatch = useAppDispatch();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    {
      getOrgInfo,
      getAllBusinessUnits,
      getDataCatalog,
      getInsuranceProviders,
      // GetAuthcode,
      // GetProviderTypes,
      getClientBoards,
      getProviderBoards,
      getAllClientIntakeStage,
      getAllProviderOnBoardingStages,
      getAllBookingsData,
    },
    dispatch
  );

  //ref for dispatchAction
  const dispatchActionRef = useRef(dispatchAction);

  const startListeners = () => {
    if (user && currentTenantId && currentTenantId.length > 0) {
      socket.on(`${currentTenantId}-${"Client"}`, (payload: BoardStage[]) => {
        let cards: CardsInitialState = {};
        payload.forEach((item) => {
          cards[item.stageName] = item.boardColumnNames;
        });
        dispatch(setClientTasks(cards));
      });
      socket.on(`${currentTenantId}-${"Provider"}`, (payload: BoardStage[]) => {
        let cards: CardsInitialState = {};
        payload.forEach((item) => {
          cards[item.stageName] = item.boardColumnNames;
        });
        dispatch(setProviderTasks(cards));
      });
    }
  };

  //dispatch for redux
  useEffect(() => {
    if (isAuthenticated && currentTenantId && currentTenantId.length > 0) {
      dispatchActionRef.current.getDataCatalog();
      dispatchActionRef.current.getInsuranceProviders();
      // dispatchActionRef.current.GetAuthcode();
      // dispatchActionRef.current.GetProviderTypes();
      dispatchActionRef.current.getAllClientIntakeStage();
      dispatchActionRef.current.getAllBookingsData();
      dispatchActionRef.current.getAllProviderOnBoardingStages();
      dispatchActionRef.current.getProviderBoards({ useLoader: false });
      dispatchActionRef.current.getClientBoards({ useLoader: false });
      // dispatchActionRef.current.getAdminUserCount();
      // dispatchActionRef.current.getVerifiedUserCount();
      socket.connect();
      socket.emit("newUser", {
        authUserId: user?.userId,
        orgId: currentTenantId,
      });
      startListeners();
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentTenantId]);

  //initialize gleap
  useEffect(() => {
    const gleapProjectId = process.env.REACT_APP_GLEAP_PROJECT_ID || "";
    if (gleapProjectId?.length > 0) {
      Gleap.initialize(gleapProjectId);
    }
  }, []);

  //fetch business unit
  useEffect(() => {
    if (isAuthenticated && currentTenantId && currentTenantId.length > 0) {
      dispatchActionRef.current.getOrgInfo({ useLoader: true });
      dispatchActionRef.current.getAllBusinessUnits();
    }
  }, [businessUnitDataChange, isAuthenticated, currentTenantId]);

  // #endregion

  return (
    <React.Fragment>
      <Routes>
        <Route
          path={AppUrls.Client.SignUp}
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route
          path={AppUrls.Client.SignIn}
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />
        <Route
          path={AppUrls.Client.IntakeForm}
          element={
            <PublicRoute>
              <Embedform />
            </PublicRoute>
          }
        />

        <Route
          path={AppUrls.Client.OrganizationOnboard}
          element={
            <PrivateRoute>
            <OrganizationOnboard />
            </PrivateRoute>
          }
        />
        <Route
          path={AppUrls.Client.composeInmail}
          element={
            <PrivateRoute>
              <ComposeMessage />
            </PrivateRoute>
          }
        />
        <Route
          path={AppUrls.Client.Schedules}
          element={
            <PrivateRoute>
              <Schedules />
            </PrivateRoute>
          }
        />
        <Route
          path={AppUrls.Client.ScheduleDetails}
          element={
            <PrivateRoute>
              <ClientSchedules />
            </PrivateRoute>
          }
        />

        {/* <Route
            path={AppUrls.Client.ClientSchedules}
            element={
              <PrivateRoute>
                <ClientSchedules />
              </PrivateRoute>
            }
          /> */}

        <Route
          path={AppUrls.Client.Map}
          element={
            <PrivateRoute>
              <Maps />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Billing}
          element={
            <PrivateRoute>
              <MyPlan />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.ViewPlan}
          element={
            <PrivateRoute>
              <ViewPlan />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Planning}
          element={
            <PrivateRoute>
              <PlanningTwo />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.PlanningDetails}
          element={
            <PrivateRoute>
              <Plannings />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Forms}
          element={
            <PrivateRoute>
              <FormTracking />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Assessments}
          element={
            <PrivateRoute>
              <Assessments />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Clients}
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Employees}
          element={
            <PrivateRoute>
              <Providers />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.ProvidersDetails}
          element={
            <PrivateRoute>
              <ProvidersDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.ClientDetails}
          element={
            <PrivateRoute>
              <ClientDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Provider}
          element={
            <PrivateRoute>
              <ProvidersDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Message}
          element={
            <PrivateRoute>
              <Message />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.CompanyProfile}
          element={
            <PrivateRoute>
              <CompanyProfile />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.CompanyUserManagement}
          element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Tools}
          element={
            <PrivateRoute>
              <Tools />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.ImportDetails}
          element={
            <PrivateRoute>
              <ImportDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.ExportClientsProviderDetails}
          element={
            <PrivateRoute>
              <ExportClientsProviderDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.BusinessSettings}
          element={
            <PrivateRoute>
              <BusinessSettings />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.AppIntegrations}
          element={
            <PrivateRoute>
              <AppIntegrations />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.MessageTemplates}
          element={
            <PrivateRoute>
              <MessageTemplateNew />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.SurveyCreator}
          element={
            <PrivateRoute>
              <SurveyCreate />
            </PrivateRoute>
          }
        />

        {/* <Route path={AppUrls.Client.DataView}/>
            <DataTable />
          */}
        <Route
          path={AppUrls.Client.Board}
          element={
            <PrivateRoute>
              <Board />
            </PrivateRoute>
          }
        />

        <Route
          path={AppUrls.Client.Home}
          element={
            <PrivateRoute>
              <Maps />
            </PrivateRoute>
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
