import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  Box,
} from "@mui/material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  GroupSettingsModel,
  Inject,
  Page,
  TextWrapSettingsModel,
  SelectionSettingsModel,
  ContextMenu,
  Toolbar,
  ContextMenuItemModel,
  Resize,
  DetailRow,
  Group,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { AppDispatch, RootState } from "../../redux/store";
import { ReactComponent as Download } from "../../assets/images/images-svg/Download.svg";
import { IFormRequestDetail } from "../../types/forms";
import {
  GetFormById,
  GetFormRequestById,
  GetFormRequests,
  ReprocessFormRequest,
} from "../../redux/features/forms/form-slice";
// import Reset from "../../assets/images/images-svg/reset.svg";
import Reset from "../../assets/images/images-png/reset2.png";
import { dateToFromNowDaily } from "../../helpers/reverse-list/reverse-list";
import CloseIcon from "@mui/icons-material/Close";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
function FormRequests(this: any) {
  //dispatch
  const dispatch = useDispatch<AppDispatch>();

  //groupings for grid
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  //handles preview
  const [isPreview, setIsPreview] = useState<boolean>(false);

  //contains survey data
  const [survey, setSurvey] = useState<any>(null);

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  let gridInstance: GridComponent;

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  // const contextMenuItems: ContextMenuItemModel[] = [
  //   { text: "Add Into Calendar", target: ".e-content", id: "addintocalendar" },
  // ];

  //stores sorted formRequests
  const [formRequests, setFormRequests] = useState<IFormRequestDetail[]>([]);

  const [url, setUrl] = useState<string | null>(null);

  const rowSelected = () => {
    if (gridInstance) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      const selectedrecords: object[] = gridInstance.getSelectedRecords();
    }
  };

  //read all formrequests
  const allFormRequests = useSelector<RootState, IFormRequestDetail[]>(
    (state) => state.formSlice.AllFormRequests
  );

  //handle SortData massage Settings
  const SortData = (formRequests: IFormRequestDetail[]) => {
    let data: IFormRequestDetail[] = [];
    if (formRequests.length > 0) {
      let result = [...formRequests].sort(
        (a: IFormRequestDetail, b: IFormRequestDetail) => {
          return (
            (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any)
          );
        }
      );
      data = [...result];
    }

    return data;
  };
  const handlePreview = async (id: string) => {
    const data = await dispatch(GetFormRequestById({ assessmentId: id }));
    let tempSurvey = new Model(data.payload?.form.formSchema);
    tempSurvey.data = data?.payload?.formData;
    tempSurvey.mode = "display";
    if (tempSurvey) {
      setSurvey(tempSurvey);
      setIsPreview(true);
    }
  };
  //#region useEffect region

  // used to fetch intake client form data
  useEffect(() => {
    dispatch(GetFormRequests({ formType: "All Forms, No Assessments" }));
  }, [dispatch]);

  useEffect(() => {
    if (allFormRequests) {
      const data = [...allFormRequests].sort((a, b) => {
        return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
      });
      setFormRequests(data);
    }
  }, [allFormRequests]); //respond to new & reprocess formRequests
  //#endregion

  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  function dataBound() {
    if (refresh) {
      gridInstance.groupColumn("name");
      refresh = false;
    }
  }

  const entityTypeTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.entityType === "Client"
            ? "Client"
            : props.entityType === "Provider"
            ? "Employee"
            : props.entityType}
        </span>
      </div>
    );
  };

  //field: entityName
  const entityNameTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.entityName}</span>
      </div>
    );
  };

  const errorMsgTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.errorMsg}</span>
      </div>
    );
  };

  //field: sentDtm
  const sentDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.sentDtm
            ? new Date(`${props.sentDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </span>
      </div>
    );
  };

  const submitDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.submitDtm
            ? new Date(`${props.submitDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </span>
      </div>
    );
  };

  const updateDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.updatedAt
            ? dateToFromNowDaily(new Date(`${props.updatedAt}`))
            : "--------"}
        </span>
      </div>
    );
  };

  //template for icons in data grid
  function materialTemplate(props: any) {
    return (
      <div>
        {props.status === "Error" && props.entityName ? (
          //show button with an action call to ReprocessFormRequest
          <div
            aria-label="reprocess"
            style={{ padding: "5px 0px", cursor: "pointer" }}
            onClick={() => {
              dispatch(
                ReprocessFormRequest({
                  id: props.id,
                  formType: props.formType,
                })
              );
            }}
          >
            <img src={Reset} height={30} width={30} className="resetImage"/>
          </div>
        ) : //else show below
        props.status === "Mapped" ? (
          //show "mapped" icon
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "15px",
            }}
          >
            <a href={props.pdfUrl}>
              <IconButton style={{ background: "transparent",padding:"0px" }}>
                <Download/>
              </IconButton>
            </a>
            <div>
              <Button
                onClick={() => {
                  handlePreview(props?.id);
                }}
                sx={{padding:"0px"}}
              >
                Preview
              </Button>
            </div>
          </div>
        ) : (
          //do not show anything
          <span></span>
        )}
      </div>
    );
  }

  //#endregion

  //#region Memoised Componant region
  //data grid table
  const dataGridTable = React.useMemo(
    () =>
      formRequests &&
      formRequests[0]?.id !== "" && (
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={SortData(formRequests)}
          // rowTemplate={template.bind(this)}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={(grid) => (gridInstance = grid as GridComponent)}
          load={load}
          width="auto"
          height="650"
          allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowTextWrap={true}
          filterSettings={{ type: "Menu" }}
          textWrapSettings={textWrapSettings}
          selectionSettings={selectionSettings}
          rowSelected={rowSelected}
          // contextMenuItems={contextMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="130"
            />
            <ColumnDirective
              headerText="Type"
              width="80"
              textAlign="Left"
              field="entityType"
              template={entityTypeTemplate}
            />
            <ColumnDirective
              headerText="Person"
              width="150"
              textAlign="Left"
              field="entityName"
              template={entityNameTemplate}
            />
            <ColumnDirective
              headerText="Form"
              width="180"
              textAlign="Left"
              field="formName"
            />
            <ColumnDirective
              headerText="Send Date"
              width="100"
              textAlign="Left"
              field="sentDtm"
              template={sentDtmTemplate}
            />
            <ColumnDirective
              headerText="Submit Date"
              width="100"
              textAlign="Left"
              field="submitDtm"
              template={submitDtmTemplate}
            />
            <ColumnDirective
              headerText="Status"
              width="70"
              textAlign="Left"
              field="status"
            />

            <ColumnDirective
              headerText="Error"
              width="150"
              textAlign="Left"
              field="errorMsg"
              template={errorMsgTemplate}
            />
            <ColumnDirective
              headerText="Updated On"
              width="100"
              textAlign="Left"
              field="updatedAt"
              template={updateDtmTemplate}
            />
            <ColumnDirective
              headerText="PDF"
              width="100"
              textAlign="Left"
              field="formPDF"
              disableHtmlEncode={false}
              template={materialTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Group,
              ContextMenu,
              Toolbar,
              Resize,
              DetailRow,
              Sort,
              Filter,
            ]}
          />
        </GridComponent>
      ),
    [formRequests]
  );
  //#endregion

  //#region useEffect region

  return (
    <>
      <div>
        <Grid sx={{ marginTop: "30px" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontWeight="bold"
            className="listingTable__title"
            style={{ marginBottom: "10px" }}
          >
            Requests & Submissions
          </Typography>

          {dataGridTable}
        </Grid>
        {isPreview && (
          <Dialog open={isPreview} className="smallModel--assessment">
            <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
              <Typography variant="h3" className="smallModel__title">
                preview
              </Typography>

              <Button
                className="smallModel__closeBtn"
                onClick={() => {
                  setIsPreview(false);
                }}
              >
                <CloseIcon style={{ color: "white" }} />
              </Button>
            </Box>
            {url === null ? (
              <div>
                <Survey id="id" model={survey} />
              </div>
            ) : (
              <iframe
                src={url}
                height="3000"
                width="700"
                title="frame"
              ></iframe>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "12px",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setIsPreview(false);
                }}
              >
                Close
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}
export default FormRequests;
