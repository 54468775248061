import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Organization, OrgData } from "../../../types/company";
import CompanyUserService from "../../../service/org.service";
import { setResponseValue } from "../api-response/api-response";
import BusinessSetting from "../../../service/business-setting.service";
import { OrgService } from "../../../constants/company-user";

const initialState: Organization = {
  dataChange: false,
  searchKeyword: "",
  orgData: {
    allowToUploadBackInsuranceCard: true,
    allowToCompleteCustomIntakeForm: false,
    allowToUploadFrontInsuranceCard: false,
    formURL: "",
    formEmbeddedCode: "",
    orgService: [],
    form: [
      {
        id: "",
        name: "",
        url: "",
        embeddedCode: "",
        createdAt: "",
        updatedAt: "",
        isJot: false,
      },
    ],
    createdAt: "",
    updatedAt: "",
    id: "",
    orgName: "",
    orgUrl: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    orgLogo: {
      containerName: "",
      fileName: "",
      key: "",
      url: "",
    },

    //stripe related
    customerId: "",
    currentSubscriptionId: "",
    currentPlanId: "",
    adminSeats: 0,
    supportEmail: "",
  },
};

const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState: initialState,
  reducers: {
    setOrganizationDataChange: (state, action: PayloadAction<null>) => {
      return {
        ...state,
        dataChange: !state.dataChange,
      };
    },
    setSearchKeyword: (state, action) => {
      return {
        ...state,
        searchKeyword: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOrganizationLogo.fulfilled,
      (state, action: PayloadAction<OrgData | undefined>) => {
        if (action.payload) {
          return {
            ...state,
            orgData: {
              ...action.payload,
            },
          };
        }
        return state;
      }
    );
    builder.addCase(
      getOrgInfo.fulfilled,
      (state, action: PayloadAction<OrgData | undefined>) => {
        if (action.payload) {
          return {
            ...state,
            orgData: {
              ...action.payload,
            },
          };
        }
        return state;
      }
    );
    // builder.addCase(UpdateSubscription.fulfilled, (state, action) => {
    //   if (action.payload?.length > 0) {
    //     return {
    //       ...state,
    //       orgData: {
    //         ...state.orgData,
    //         subscription: action.payload,
    //       },
    //     };
    //   }
    //   return state;
    // });
    // builder.addCase(updateAdminSeat.fulfilled, (state, action) => {
    //   if (action.payload?.length > 0) {
    //     return {
    //       ...state,
    //       orgData: {
    //         ...state.orgData,
    //         subscription: action.payload,
    //       },
    //     };
    //   }
    //   return state;
    // });
  },
});

export const setupNewOrganization = createAsyncThunk(
  "organizationSlice/setupNewOrganization",
  async (
    {
      name,
      orgUrl,
      logoFile,
      orgService,
    }: {
      name: string;
      orgUrl: string;
      logoFile: any;
      orgService: string[];
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      //note: backend handles org, user creations
      const { status, data, message } =
        await CompanyUserService.postOrganization(
          name,
          orgUrl,
          logoFile,
          orgService
        );
      // console.log("status, data:", status, data);
      if (status) {
        // dispatch(setCurrentTenant(data.descope_tenantId));
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getOrganizationLogo = createAsyncThunk(
  "organizationSlice/getOrganizationLogo",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, data } = await CompanyUserService.getOrganizationLogo();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateOrgLogo = createAsyncThunk(
  "organizationSlice/updateOrgLogo",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      // const data = aw
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getOrgInfo = createAsyncThunk(
  "organizationSlice/getOrgInfo",
  async ({ useLoader }: { useLoader: boolean }, { getState, dispatch }) => {
    if (useLoader) {
      dispatch(setResponseValue({ name: "pending", value: true }));
    }
    try {
      const { status, data } = await CompanyUserService.getOrgInfo();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      if (useLoader) {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

export const updateOrg = createAsyncThunk(
  "organizationSlice/updateOrg",
  async (
    {
      name,
      url,
      tenantId,
      service,
      supportEmail,
    }: {
      name: string;
      url: string;
      tenantId: string;
      service: OrgService[];
      supportEmail: string;
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await CompanyUserService.updateOrgInfo(
        name,
        url,
        service,
        supportEmail,
        tenantId
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return status;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateBusinessSettings = createAsyncThunk(
  "organization/business",
  async (
    {
      UploadBackInsuranceCard,
      CompleteCustomIntakeForm,
      UploadFrontInsuranceCard,
    }: {
      UploadBackInsuranceCard: boolean;
      CompleteCustomIntakeForm: boolean;
      UploadFrontInsuranceCard: boolean;
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      allowToUploadBackInsuranceCard: UploadBackInsuranceCard,
      allowToCompleteCustomIntakeForm: CompleteCustomIntakeForm,
      allowToUploadFrontInsuranceCard: UploadFrontInsuranceCard,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await BusinessSetting.postBusinessSetting(
        payloadData
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// export const updateAdminSeat = createAsyncThunk(
//   "organization/updateAdminSeat",
//   async ({ adminCount }: { adminCount: number }, { dispatch }) => {
//     dispatch(setResponseValue({ name: "pending", value: true }));
//     try {
//       const { status, message, data } = await updateAdminSeatPLan(adminCount);
//       if (status) {
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//         return data;
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({ name: "message", value: e?.response?.data?.message })
//       );
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

export default organizationSlice;

//post parent settings form
/* //deprecated!!
export const postParentSettings = createAsyncThunk(
  "/organization/business/new/form",
  async (
    {
      formname,
      formURL,
      formEmbeddedCode,
      tenantId,
    }: {
      formname: string;
      formURL: string;
      formEmbeddedCode: string;
      tenantId: string;
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      name: formname,
      url: formURL,
      embeddedCode: formEmbeddedCode,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await BusinessSetting.postParentSettingForm(
        payloadData
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update parent settings form
export const updateParentSettings = createAsyncThunk(
  "/organization/business/new/form/",
  async (
    {
      id,
      formname,
      formURL,
      formEmbeddedCode,
      tenantId,
    }: {
      id: string;
      formname: string;
      formURL: string;
      formEmbeddedCode: string;
      tenantId: string;
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      name: formname,
      url: formURL,
      embeddedCode: formEmbeddedCode,
    };
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await BusinessSetting.updateParentSettingForm(
        id,
        payloadData
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);


//delete Parent settings form
export const deleteParentSettings = createAsyncThunk(
  "/organization/business/new/form/",
  async (
    {
      id,
      tenantId,
    }: {
      id: string;
      tenantId: string;
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await BusinessSetting.deleteParentSettingForm(
        id
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setSearchKeyword, setOrganizationDataChange } =
  organizationSlice.actions;
**/
